import { connect } from 'react-redux';
import {authenticateToken, addTab} from '../core-actions';
import CoreToolbar from '../components/core-toolbar';

const mapStateToProps = (state) => {
    return {
        appId: state.core.active,
        title: state.core.title,
        theme: state.core.theme
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addTab: (params) => {
            dispatch(addTab(params))
        },
        authenticateToken: (apiKey, callback) => {
            authenticateToken(apiKey, callback)
        }
    };
}

const CoreToolbarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CoreToolbar)

export default CoreToolbarContainer;