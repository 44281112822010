import { connect } from 'react-redux';
import RolesForm from '../components/roles-form';

import { getRole, getAssignedRoles, assignRole} from '../roles-utils';

const mapStateToProps = (state) => {
    if(!state[state.core.tabObject.activeApp])
        return {};
    
    let appId = state.core.tabObject.activeApp;
    let tabKey = state.core.tabObject.activeKey;
    let tabs = state.core.tabObject.tabs;

    return {
        appId,
        tabKey,
        tabs,
        state: state[appId],
        theme: state.core.theme,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRole: (state, callback) => {
            getRole(state, callback)
        },
        getAssignedRoles: (state, callback) => {
            getAssignedRoles(state, callback)
        },
        assignRole: (state, callback) => {
            assignRole(state, callback)
        }
    };
};

const RolesFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RolesForm);

export default RolesFormContainer;