const config = require('./config.json');

let hostName = window.location.hostname;
let protocol = window.location.protocol;

const Globals = {
    title: "Agilit-e Demo Portal",
    version: "6.1.0",
    releaseDate: "26th February 2019",
    apiUrl: config.deployType === "local" ? protocol + "//" + hostName + ":6010" : config.apiUrl,
    dropzoneUrl: config.deployType === "local" ? protocol + "//" + hostName + ":6010/files/agilite" : config.apiUrl + "/files/agilite"
};

export default Globals;