import React from 'react';
import agiliteLogo from '../../images/agilite-logo-full-web.png';
import apiKeyImage from '../../images/agilite-demo-add-api-key.jpg';

import {Row, Col, Card} from 'antd';

const CoreTabHome = ({initHomePage}) => (
  <div style={{ background: '#fff' }}>
    <img src={agiliteLogo} style={{width:300}} alt="Agilit-e" />
    <Row gutter={24} type="flex" justify="center">
        <Col xs={24} sm={24} md={16} lg={16}>
          <Card title="Welcome to the Agilit-e Demo Portal" bordered={true}>
            <p>In this Demo Portal, you will be able to test various microservices you've profiled in Agilit-e. Before starting, be sure to first add an API Key for your Agilit-e environment. You achieve this using the top-right menu in this Portal.</p>
            <p><img src={apiKeyImage} style={{width:"90%"}} alt="Agilit-e API Key" /></p>
          </Card>
        </Col>
    </Row>
  </div>
)

export default CoreTabHome;