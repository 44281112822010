import React from 'react';
import { Tabs } from 'antd';
import  CoreTabHome from './core-tab-home';
import Enums from '../../utils/enums';

const  TabPane  = Tabs.TabPane;

class CoreLanding extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            activeKey: ""
        };

        this.onEdit = this.onEdit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        this.setState({activeKey: this.props.activeKey});

        this.props.addTab({
            content: <CoreTabHome />,
            title: "Home",
            key: Enums.APP_IDS.HOME,
            app: Enums.APP_IDS.HOME,
            tabType: Enums.VALUES_STRINGS.EMPTY,
            closable: false
        });
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.activeKey !== this.props.activeKey)
            this.setState({activeKey: this.props.activeKey});
    }

    onEdit(targetTab){
        this.props.closeTab(this.state.activeKey, targetTab, this.props.tabs);
    }

    onChange(key){
        this.props.changeTab(key, this.props.tabs);
    }

    render(){
        return(
            <div className="App">
                <Tabs
                    type="editable-card"
                    hideAdd
                    activeKey={this.state.activeKey}
                    onEdit={this.onEdit}
                    onChange={this.onChange}
                    tabBarStyle={{textAlign: "left"}}
                >
                    {this.props.tabs.map(pane => <TabPane forceRender={true} tab={pane.title} key={pane.key} closable={pane.closable}>{pane.content}</TabPane>)}
                </Tabs>
            </div>
        )
    }
}

export default CoreLanding;
