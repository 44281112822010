import { connect } from 'react-redux';
import TemplateForm from '../components/template-form';

import { execute, fetchKeys } from '../template-utils';

const mapStateToProps = (state) => {
    if(!state[state.core.tabObject.activeApp])
        return {};
    
    let appId = state.core.tabObject.activeApp;
    let tabKey = state.core.tabObject.activeKey;
    let tabs = state.core.tabObject.tabs;

    return {
        appId,
        tabKey,
        tabs,
        state: state[appId],
        theme: state.core.theme,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchKeys: (callback) => {
            fetchKeys(callback)
        },
        execute: (key, paramData, callback) => {
            execute(key, paramData, callback)
        }
    };
};

const TemplateFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplateForm);

export default TemplateFormContainer;