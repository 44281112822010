import React from 'react';
import {Row, Col} from 'antd';

/*  
    Custom Components Import
*/
import CoreToolbarContainer from '../containers/core-toolbar-container';
import CoreLandingContainer from '../containers/core-landing-container';
import Enums from '../../utils/enums';

class CoreApp extends React.Component {
	componentDidMount(){
		window.addEventListener("beforeunload", function (e) {		
			(e || window.event).returnValue = Enums.MESSAGES.AGILITE_CLOSE; //Gecko + IE
			return Enums.MESSAGES.AGILITE_CLOSE; //Gecko + Webkit, Safari, Chrome etc.
		});	
	}
	//TODO: this.props.app will in essense always be 'home'. We need to refactor this logic
	//and it's container
	render() {
		return (
			<div>
				<Row type="flex" justify="center">
					<Col xs={24} sm={24} md={24} lg={24}>
						<CoreToolbarContainer />
						<div style={{marginTop:20}}>
							<CoreLandingContainer />
						</div>	
					</Col>
				</Row>
			</div>
		);
	}
}

export default CoreApp;