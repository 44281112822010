import { connect } from 'react-redux';
import BpmForm from '../components/bpm-form';

import { getById, getByProfileKey, execute, registerBPMRecord, getRecordState, getRelevantRecords, getActiveSteps, getActiveUsers, fetchKeys } from '../bpm-utils';

const mapStateToProps = (state) => {
    if(!state[state.core.tabObject.activeApp])
        return {};
    
    let appId = state.core.tabObject.activeApp;
    let tabKey = state.core.tabObject.activeKey;
    let tabs = state.core.tabObject.tabs;

    return {
        appId,
        tabKey,
        tabs,
        state: state[appId],
        theme: state.core.theme,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchKeys: (callback) => {
            fetchKeys(callback)
        },
        getById: (state, callback) => {
            getById(state, callback)
        },
        getByProfileKey: (state, callback) => {
            getByProfileKey(state, callback)
        },
        execute: (state, callback) => {
            execute(state, callback)
        },
        registerBPMRecord: (state, callback) => {
            registerBPMRecord(state, callback)
        },
        getRecordState: (state, callback) => {
            getRecordState(state, callback)
        },
        getRelevantRecords: (state, callback) => {
            getRelevantRecords(state, callback)
        },
        getActiveSteps: (state, callback) => {
            getActiveSteps(state, callback)
        },
        getActiveUsers: (state, callback) => {
            getActiveUsers(state, callback)
        }
    }
};

const BpmFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BpmForm);

export default BpmFormContainer;