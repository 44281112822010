import { connect } from 'react-redux';
import DataMappingForm from '../components/data-mapping-form';

import { execute, fetchKeys } from '../data-mapping-utils';

const mapStateToProps = (state) => {
    if(!state[state.core.tabObject.activeApp])
        return {};
    
    let appId = state.core.tabObject.activeApp;
    let tabKey = state.core.tabObject.activeKey;
    let tabs = state.core.tabObject.tabs;

    return {
        appId,
        tabKey,
        tabs,
        state: state[appId],
        theme: state.core.theme,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchKeys: (callback) => {
            fetchKeys(callback)
        },
        execute: (key, paramData, callback) => {
            execute(key, paramData, callback)
        }
    };
};

const DataMappingFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DataMappingForm);

export default DataMappingFormContainer;