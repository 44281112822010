import Enums from "../utils/enums";
import Globals from "../utils/globals";
import Axios from "axios";
import MemoryStore from "../utils/memory-store";

export const getRole = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.POST,
        url: `${Globals.apiUrl}/roles/getRole`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.ROLE_NAMES] = state.roleName;
    config.headers[Enums.HEADER_PROPS.CONDITIONAL_LEVELS] = state.conditionalLevels;

    config.data = state.parameters;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const getAssignedRoles = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/roles/getAssignedRoles`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.PROCESS_KEY] = state.processKey;
    config.headers[Enums.HEADER_PROPS.BPM_RECORD_ID] = state.recordId;
    config.headers[Enums.HEADER_PROPS.ROLE_NAME] = state.roleName;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const assignRole = (state, callback) => {
    console.log(state);
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/roles/assignRole`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.PROCESS_KEY] = state.processKey;
    config.headers[Enums.HEADER_PROPS.BPM_RECORD_ID] = state.recordId;
    config.headers[Enums.HEADER_PROPS.ROLE_NAME] = state.roleName;
    config.headers[Enums.HEADER_PROPS.CURRENT_USER] = state.currentUsers
    config.headers[Enums.HEADER_PROPS.RESPONSIBLE_USERS] = state.responsibleUser

    console.log(config.headers);
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};