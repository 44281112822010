import React from 'react';
import moment from 'moment';

import {List, Form, Button, Col, Row, Card, Select, message, Comment, Switch} from 'antd';

import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/xcode';

const CommentList = ({ comments }) => (
	<List
	  dataSource={comments}
	  header={`${comments.length} ${comments.length > 1 ? 'results' : 'result'}`}
	  itemLayout="horizontal"
	  renderItem={props => <Comment {...props} />}
	/>
);

class NumberingForm extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			key: "",
			number: "",
			numbers: [],
			parameters: JSON.stringify({
				param1: "",
				param2: ""
			}, null, '\t'),
			result: "",
			profileKeys: []
		};

		//Setup Event Binding
		this.onFieldChange = this.onFieldChange.bind(this);
		this.generate = this.generate.bind(this);
	}

	componentDidMount(){
		let tmpThis = this;
		let tmpArr = [];
		let msg = "";
		let success = true;
		
		this.props.fetchKeys(function(err, result){
			if(err){
				success = false;
				msg = err;
			}else if(result.status !== 200){
				success = false;
				msg = result.data.errorMessage;
			}

			if(success){
				result.data.map((entry) => {
					return tmpArr.push(entry.data.key);
				})

				tmpThis.setState({
					profileKeys: tmpArr
				})

			}else{
				return message.error(msg);
			}
		})
	}

	onFieldChange(key, value){
		let tmp = {};
		tmp[key] = value;
		this.setState(tmp);
	}

	generate = () => {
		let tmpThis = this;
		let msg = "";
		let success = true;

		//First Validate
		if(tmpThis.state.key === "")
			return message.error("Please provide a Numbering Profile Key");

		tmpThis.props.generate(tmpThis.state.key, tmpThis.state.parameters, function(err, result){
			if(err){
				success = false;
				msg = err;
			}else if(result.status !== 200){
				success = false;
				msg = result.data.errorMessage;
			}else{
				msg = "Numbering Service executed successfully";
			}

			if(success){
				message.success(msg);

				//Update the state with new values
				tmpThis.setState({
					result: result.data,
					number: result.data,
					numbers: [
						{
						  author: tmpThis.state.key,
						  content: <Card>{result.data}</Card>,
						  datetime: moment().toString(),
						},
						...tmpThis.state.numbers,
					  ],
				});
			}else{
				return message.error(msg);
			}
		});
	}
	  
	render(){
		return(
			<Row  gutter={23} type="flex" justify="center"> 
				<Col xs={23}>
					<Card
						title="Numbering Demo"
						style={{textAlign: "left"}}
						type="inner"
					>
						<Form
							autoComplete="off"
							onSubmit={e => {
								e.preventDefault()
								this.generate();
							}}
						>
							<Row gutter={24}>
								<Col xs={24} sm={24} md={7} lg={7}>
									<Form.Item>
										<span style={{color: "red"}}>* </span>Profile Key
										<Select
											onChange={(e) => {
												this.onFieldChange("key", e)
											}}
										>
											{this.state.profileKeys.map((entry) => {
												return <Select.Option value={entry}>{entry}</Select.Option>
											})}
										</Select>
									</Form.Item>
									<Form.Item>
									Request Body (JSON)
										<AceEditor
											showPrintMargin={false}
											cursorStart={1}
											mode="json"
											theme="xcode"
											style={{width:"100%", height: 300}}
											name="aceEditor2"
											value={this.state.parameters}
											setOptions={{
												showLineNumbers: true,
												newLineMode:"unix"
											}}
											wrapEnabled={true}
											onChange={(e) => {
												this.onFieldChange("parameters", e);
											}}
										/>
									</Form.Item>
									<Form.Item>
									<Button
										type="default"
										htmlType="submit"
										style={{backgroundColor:"#67AD5B", color:"white", width: "auto", marginRight: "10px", float: "left"}}
									>
										Run
									</Button>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={9} lg={9}>
								{this.state.result !== "" ?
								<div>
								<h4>{`Response`}</h4>
									<AceEditor
										readOnly={true}
										showPrintMargin={false}
										cursorStart={10}
										mode="json"
										theme="xcode"
										style={{width:"100%"}}
										name="aceEditor2"
										value={JSON.stringify(this.state.result, null, "\t")}
										setOptions={{
											showLineNumbers: true,
											newLineMode:"unix"
										}}
										wrapEnabled={true}
									/>
								</div>
								:null}
								</Col>
								<Col xs={24} sm={24} md={8} lg={8}>
									<div>
										{this.state.numbers.length > 0 && <CommentList comments={this.state.numbers} />}
									</div>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		)
	}
}

export default NumberingForm;
