import { connect } from 'react-redux';
import ConnectorForm from '../components/connectors-form';

import { execute, fetchKeys } from '../connectors-utils';

const mapStateToProps = (state) => {
    if(!state[state.core.tabObject.activeApp])
        return {};
    
    let appId = state.core.tabObject.activeApp;
    let tabKey = state.core.tabObject.activeKey;
    let tabs = state.core.tabObject.tabs;

    return {
        appId,
        tabKey,
        tabs,
        state: state[appId],
        theme: state.core.theme,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchKeys: (callback) => {
            fetchKeys(callback)
        },
        execute: (key, routeKey, paramData, callback) => {
            execute(key, routeKey, paramData, callback)
        }
    };
};

const ConnectorFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectorForm);

export default ConnectorFormContainer;