//MODULECONDITION
export const columnTemplates = {
  keywords: [
    {
      title: "Keyword Label",
      dataIndex: "label",
      key: "label",
      width: 200
    }, {
      title: "Keyword Value",
      dataIndex: "value",
      key: "value",
      width: 200,
    }
  ],
  roles: {
    roleId:[
      {
        title: "Role ID",
        dataIndex: "roleIds",
        key: "roleId",
        width: 200
      }
    ],
    responsibleUser:[
      {
        title: "Responsible User",
        dataIndex: 'responsibleUsers',
        key: "responsibleUser",
        width: 200
      }
    ]
  }
};