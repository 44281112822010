import React from 'react';
import moment from 'moment';
import {columnTemplates} from '../../utils/templates';

import {List, Form, Button, Col, Row, Card, Input, message, Comment, Switch, Select, Table} from 'antd';

import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/xcode';

const CommentList = ({ comments }) => (
	<List
	  dataSource={comments}
	  header={`${comments.length} ${comments.length > 1 ? 'results' : 'result'}`}
	  itemLayout="horizontal"
	  renderItem={props => <Comment {...props} />}
	/>
);

class RolesForm extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			roleName: "",
			role: "",
			roles: [],
			parameters: JSON.stringify({
				param1: "",
				param2: ""
			}, null, '\t'),
			result: "",
			conditionalLevels: "",
			actionType: "getRole",
			profileKey: "",
			recordId: "",
			currentUsers: "",
			responsibleUser: ""
		};

		//Setup Event Binding
		this.onFieldChange = this.onFieldChange.bind(this);
		this.execute = this.execute.bind(this);
	}

	onFieldChange(key, value){
		let tmp = {};
		tmp[key] = value;
		this.setState(tmp);
	}

	execute = () => {
		let tmpThis = this;
		let msg = "";
		let success = true;
		let content = null;
		let dataSourceIds = [];
		let dataSourceResUsers = [];

		//First Validate

		tmpThis.props[tmpThis.state.actionType](tmpThis.state, function(err, result){
			if(err){
				success = false;
				msg = err;
			}else if(result.status !== 200){
				success = false;
				msg = result.data.errorMessage;
			}else{
				msg = "Roles Service executed successfully";
			}

			if(success){
				message.success(msg);

				switch(tmpThis.state.actionType){
					case "getRole":
						content = (
							<div>
								<Card title="Role Id" style={{margin: 5}}>
										{result.data.roleIds.map(function(entry){
											return(
												<span>{entry}<br /></span>
											) 
										})}
								</Card>
								<Card title="Responsible User" style={{margin: 5}}>
									{result.data.responsibleUsers.map(function(entry){
										return(
											<span>{entry}<br /></span>
										) 
									})}
								</Card>
							</div>
						)
						break;
					case "assignRole":
					case "getAssignedRoles":
						content = (
							<Card>
							  {`Success: ${JSON.stringify(success)}`}
							  <br />
							  {`Status: ${JSON.stringify(result.status)}`}
							  </Card>
						)
						break;
					default:
						content = (
							<Card>
							  {`Success: ${JSON.stringify(success)}`}
							  <br />
							  {`Status: ${JSON.stringify(result.status)}`}
							</Card>
						)
				}

				tmpThis.setState({
					result: result.data,
					role: result.data,
					roles: [
						{
						  author: tmpThis.state.actionType,
						  content,
						  datetime: moment().toString(),
						},
						...tmpThis.state.roles,
					  ],
				});
			}else{
				return message.error(msg);
			}
		});
	}
	  
	render(){
		return(
			<Row  gutter={23} type="flex" justify="center"> 
				<Col xs={23}>
					<Card
						title="Roles Demo"
						style={{textAlign: "left"}}
						type="inner"
					>
						<Form
							autoComplete="off"
							onSubmit={e => {
								e.preventDefault()
								this.execute();
							}}
						>
							<Row gutter={24}>
								<Col xs={24} sm={24} md={7} lg={7}>
									<Form.Item>
										Action Type
										<Select
											defaultValue={this.state.actionType}	
											onChange={(e) => {
												this.onFieldChange("actionType", e)
											}} 
										>
											<Select.Option value="getRole">Get Role</Select.Option>
											<Select.Option value="getAssignedRoles">Get Assigned Roles</Select.Option>
											<Select.Option value="assignRole">Assign Role</Select.Option>
										</Select>
									</Form.Item>
									{this.state.actionType === "getAssignedRoles"  ?
										<Form.Item>
											Role Names (separate using commas)
											<Input
												onChange={(e) => {
													this.onFieldChange("roleName", e.target.value)
												}}
											/>
										</Form.Item>
									:
										<Form.Item>
											<span style={{color: "red"}}>* </span>Role Name
											<Input
												placeholder="The name of the Role Profile"
												onChange={(e) => {
													this.onFieldChange("roleName", e.target.value)
												}}
												required={true}
											/>
										</Form.Item>
									}
									{this.state.actionType === "getAssignedRoles" || this.state.actionType === "assignRole" ?
									<Form.Item>
										<span style={{color: "red"}}>* </span>BPM Record Id
										<Input
											onChange={(e) => {
												this.onFieldChange("recordId", e.target.value)
											}}
											required={true}
										/>
									</Form.Item>
									: null}
									{this.state.actionType === "getAssignedRoles" || this.state.actionType === "assignRole" ?
										<Form.Item>
											<span style={{color: "red"}}>* </span>Profile Key
											<Input
												onChange={(e) => {
													this.onFieldChange("profileKey", e.target.value)
												}}
												required={true}
											/>
										</Form.Item>
									: null}
									{this.state.actionType === "assignRole" ?
									<Form.Item>
										<span style={{color: "red"}}>* </span>Current Users (separate using commas)
										<Input
											onChange={(e) => {
												this.onFieldChange("currentUsers", e.target.value)
											}}
											required={true}
										/>
									</Form.Item>
									: null}
									{this.state.actionType === "assignRole" ?
									<Form.Item>
										<span style={{color: "red"}}>* </span>Responsible User
										<Input
											onChange={(e) => {
												this.onFieldChange("responsibleUser", e.target.value)
											}}
											required={true}
										/>
									</Form.Item>
									: null}
									{this.state.actionType === "getRole" ?
									<Form.Item>
										Conditional Levels (separate using commas)
										<Input
											placeholder="Keys used to return Role profile based on Levels"
											onChange={(e) => {
												this.onFieldChange("conditionalLevels", e.target.value)
											}}
										/>
									</Form.Item>
									: null}
									{this.state.actionType === "getRole" ?
									<Form.Item>
									Request Body (JSON)
										<AceEditor
											showPrintMargin={false}
											cursorStart={1}
											mode="json"
											theme="xcode"
											style={{width:"100%", height: 300}}
											name="aceEditor2"
											value={this.state.parameters}
											setOptions={{
												showLineNumbers: true,
												newLineMode:"unix"
											}}
											wrapEnabled={true}
											onChange={(e) => {
												this.onFieldChange("parameters", e);
											}}
										/>
									</Form.Item>
									: null}
									<Form.Item>
										<Button
											type="default"
											htmlType="submit"
											style={{backgroundColor:"#67AD5B", color:"white", width: "auto", marginRight: "10px", float: "left"}}
										>
											Run
										</Button>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={9} lg={9}>
								{this.state.result !== "" ?
								<div>
								<h3>{`Response`}</h3>
									<AceEditor
										readOnly={true}
										showPrintMargin={false}
										cursorStart={10}
										mode="json"
										theme="xcode"
										style={{width:"100%"}}
										name="aceEditor2"
										value={JSON.stringify(this.state.result, null, "\t")}
										setOptions={{
											showLineNumbers: true,
											newLineMode:"unix"
										}}
										wrapEnabled={true}
									/>
								</div>
								:null}
								</Col>
								<Col xs={24} sm={24} md={8} lg={8}>
									<div>
										{this.state.roles.length > 0 && <CommentList comments={this.state.roles} />}
									</div>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		)
	}
}

export default RolesForm;
