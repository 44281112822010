const Enums = {
    APP_IDS: {
        HOME: "home",
        NODE_RED: "nodered",
        KEYWORDS: "keywords",
        NUMBERING: "numbering",
        CONNECTORS: "connectors",
        DATA_MAPPING: "datamapping",
        TEMPLATES: "templates",
        BPM: "bpm",
        ROLES: "roles",
        TEAM_USERS: "teamusers",
        API_KEYS: "apikeys",
        BOT_BUILDER: "botbuilder"
    },
    APP_NAMES: {
        home: "Home",
        userprofile: "User Profile",
        nodered: "Node-RED",
        keywords: "Keywords",
        numbering: "Numbering",
        connectors: "Connectors",
        datamapping: "Data Mapping",
        templates: "Templates",
        bpm: "BPM",
        roles: "Roles",
        teamusers: "Team Users",
        apikeys: "API Keys",
        botbuilder: "Bot Builder"

    },
    APP_VIEW_TITLES: {
        keywords: "Keywords Demo",
        numbering: "Numbering Demo",
        connectors: "Connectors Demo",
        datamapping: "Data Mapping Demo",
        templates: "Templates Demo",
        bpm: "BPM Demo",
        roles: "Roles Demo",
        botbuilder: "Bot Builder Demo"
    },
    VALUES_STRINGS: {
        EMPTY: "",
        SPACE: " ",
        COLON: ":",
        ZERO: "0",
        NEW_LOWER_CASE: "new",
        NEW_PROPER_CASE: "New",
        APPLICATION_JSON: "application/json",
        DATA: "data",
        ADMINISTRATION: "Administration",
        MODULES: "Modules",
        PORTAL_MENU: "Portal Menu",
        USER_PROFILE: "userProfile",
        VIEW: "view",
        FORM: "form"
    },
    HEADER_PROPS: {
        API_KEY: "api-key",
        TEAM_NAME: "team-name",
        TEAM_ID: "team-id",
        RECORD_ID: "record-id",
        CONTENT_TYPE: "Content-Type",
        INCLUDE_MODULES: "include-modules",
        PROFILE_KEY: "profile-key",
        PROCESS_KEY: "process-key",
        ROUTE_KEY: "route-key",
        ROLE_NAMES: "role-names",
        ROLE_NAME: "role-name",
        CONDITIONAL_LEVELS: "conditional-levels",
        BPM_RECORD_ID: "bpm-record-id",
        CURRENT_USER: "current-user",
        RESPONSIBLE_USERS: "responsible-users"
    },
    REQ_TYPE: {
        GET: "get",
        POST: "post",
        PUT: "put",
        DELETE: "delete"
    },
    MESSAGES: {
        AGILITE_CLOSE: "Are you sure you want to close the Agilit-e Admin Portal?"
    }
};

export default Enums;