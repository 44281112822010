import theme from '../utils/agilite-theme.json';
import Globals from '../utils/globals';
import Enums from '../utils/enums';

const coreState = {
    app: Enums.APP_IDS.HOME,//TODO: We might not need this anymore
    title: Globals.title,
    theme,
    tabObject: {
        tabs: [],
        activeKey: Enums.APP_IDS.HOME,
        activeApp: Enums.VALUES_STRINGS.EMPTY,
        tabType: Enums.VALUES_STRINGS.EMPTY
    }
};

export default coreState;