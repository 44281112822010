import React from 'react';
import { Layout, Drawer, Menu, Dropdown, Modal, Input, message} from 'antd';
import {Icon} from 'react-fa';
import Enums from '../../utils/enums';
import MemoryStore from '../../utils/memory-store';

//MODULECONDITION
import NumberingFormContainer from '../../numbering/containers/numbering-form-container';
import KeywordsFormContainer from '../../keywords/containers/keywords-form-container';
import TemplatesFormContainer from '../../templates/containers/template-form-container';
import DataMappingFormContainer from '../../data-mapping/containers/data-mapping-form-container';
import RolesFormContainer from '../../roles/containers/roles-form-container';
import ConnectorFormContainer from '../../connectors/containers/connectors-form-container';
import BpmFormContainer from '../../bpm/containers/bpm-form-container';

const {TextArea} = Input;
const { Header } = Layout;
const SubMenu = Menu.SubMenu;

class CoreToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuLeftVisible: false,
      teamId: MemoryStore.teamId,
      apiKey: MemoryStore.apiKey,
      hasApiKey: false,
      teamName: MemoryStore.apiKey ? `TEAM: ${this.state.teamId.toUpperCase()} ` : "No API Key defined. Click here to action",
      createTeamDialog: {
        apiKey: "",
        open: false
      }
    };

    this.switchMenu = this.switchMenu.bind(this);
    this.changeApiKey = this.changeApiKey.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.openTeamDialog = this.openTeamDialog.bind(this);
    this.closeTeamDialog = this.closeTeamDialog.bind(this);
  }

  componentWillUpdate(){
    if(!this.state.hasApiKey && MemoryStore.apiKey){
      this.setState({
        apiKey: MemoryStore.apiKey,
        teamId: MemoryStore.teamId,
        hasApiKey: true
      });
    }else if(this.state.hasApiKey && !MemoryStore.apiKey){
      this.setState({
        teamId: "", 
        apiKey: "", 
        hasApiKey: false
      });
    }
  }

  openTeamDialog = () => {
		this.setState({
      createTeamDialog: {
        apiKey: "",
        open: true
      }
    });
	};

	closeTeamDialog = () => {
		this.setState({
      createTeamDialog: {
        apiKey: "",
        open: false
      }
    });
  };

  switchMenu(e){
    let key = e.key;
    let app = e.key;
    let content = null;
    let closable = true;
    let canAddTab = true;
    let tabType = Enums.VALUES_STRINGS.VIEW;
    let title = Enums.APP_VIEW_TITLES[key];

    //MODULECONDITION
    switch(key){
      case Enums.APP_IDS.KEYWORDS:
        content = <KeywordsFormContainer />;
        break;
      case Enums.APP_IDS.NUMBERING:
        content = <NumberingFormContainer />;
        break;
      case Enums.APP_IDS.TEMPLATES:
        content = <TemplatesFormContainer />;
        break;
      case Enums.APP_IDS.DATA_MAPPING:
        content = <DataMappingFormContainer />;
        break;
      case Enums.APP_IDS.ROLES:
        content = <RolesFormContainer />;
        break;
      case Enums.APP_IDS.CONNECTORS:
        content = <ConnectorFormContainer />;
        break;
      case Enums.APP_IDS.BPM:
        content = <BpmFormContainer />;
        break;
      default:  
        //Do Nothing
        return null;
    };

    if(canAddTab){
      this.props.addTab({
        content,
        title,
        key,
        app,
        tabType,
        closable
      });
  
      this.setState({menuLeftVisible: false}) 
    }
  }
  
  changeApiKey(value){
    return this.setState({
      createTeamDialog: { 
        ...this.state.createTeamDialog,
        apiKey: value
      }
    });
  }

  authenticate = () => {
		let tmpThis = this;
		let msg = "";
		let success = true;

    //First Validate
    if(tmpThis.state.createTeamDialog.apiKey === "")
      return message.error("Please provide an Agilit-e API Key before submitting");

		tmpThis.props.authenticateToken(tmpThis.state.createTeamDialog.apiKey, function(err, result){
			if(err){
				success = false;
        msg = err;
			}else if(result.status !== 200){
				success = false;
				msg = result.data.errorMessage;
			}else{
        msg = "Team: " + result.data.teamId + " successfully created";
      }

			if(success){
        message.success(msg);

				//Update the state with new values
        tmpThis.setState({
          teamName: `TEAM: ${result.data.teamId.toUpperCase()} `,
          teamId: result.data.teamId,
          apiKey: tmpThis.state.createTeamDialog.apiKey,
          createTeamDialog: {
            apiKey: "",
            open: false
          }
        });
			}else{
        return message.error(msg);
			}
		});
	}

  showLeftDrawer = () => {
    this.setState({
      menuLeftVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      menuLeftVisible: false
    });
  };

  render(){
    const teamMenu = (
      <Menu style={{marginTop: "-20px"}}>
        <Menu.Item key="change_api_key" onClick={this.openTeamDialog}>Set API Key</Menu.Item>
      </Menu>
    );
    
    return (
      <div>
        <Layout>
          <Header style={{backgroundColor: this.props.theme.primary, color: "white", fontSize: "16pt", height: "50px"}}>
            <div className="headerContents">
              {this.props.title}
              <Icon name="bars" className="menu-left" onClick={this.showLeftDrawer} /> 
              <Dropdown overlay={teamMenu} trigger={['click']} className="team_dropdown">
                <div className="ant-dropdown-link" style={{cursor: "pointer"}}>{this.state.teamName}  <Icon name="caret-down" theme="filled" /></div>
              </Dropdown>
            </div>  
          </Header>
        </Layout>
          <Drawer
            title={Enums.VALUES_STRINGS.PORTAL_MENU}
            placement="left"
            closable={false}
            width="300px"
            onClose={this.onClose}
            visible={this.state.menuLeftVisible}
            className="menu-drawer"
          >
            <Menu
              onClick={this.switchMenu}
              style={{ width: 250 }}
              defaultSelectedKeys={[Enums.APP_IDS.HOME]}
              defaultOpenKeys={["subMenu1"]}
              mode="inline"
              >
              <Menu.Item key={Enums.APP_IDS.HOME} onClick={ () => {this.setState({menuLeftVisible: false})}}>{Enums.APP_NAMES[Enums.APP_IDS.HOME]}</Menu.Item>
              <SubMenu key="subMenu1" title={<span><Icon size="lg" name="align-justify" />  <span>{Enums.VALUES_STRINGS.MODULES}</span></span>}>
                <Menu.Item key={Enums.APP_IDS.KEYWORDS}>
                  <Icon name="arrow-right" /> {Enums.APP_NAMES[Enums.APP_IDS.KEYWORDS]}
                </Menu.Item>
                <Menu.Item key={Enums.APP_IDS.NUMBERING}>
                  <Icon name="list-ol" /> {Enums.APP_NAMES[Enums.APP_IDS.NUMBERING]}
                </Menu.Item>
                  <Menu.Item key={Enums.APP_IDS.CONNECTORS}>
                    <Icon name="plug" /> {Enums.APP_NAMES[Enums.APP_IDS.CONNECTORS]}
                  </Menu.Item>
                  <Menu.Item key={Enums.APP_IDS.DATA_MAPPING}>
                    <Icon name="exchange" /> {Enums.APP_NAMES[Enums.APP_IDS.DATA_MAPPING]}
                  </Menu.Item>
                  <Menu.Item key={Enums.APP_IDS.TEMPLATES}>
                    <Icon name="columns" /> {Enums.APP_NAMES[Enums.APP_IDS.TEMPLATES]}
                  </Menu.Item>
                  <Menu.Item key={Enums.APP_IDS.BPM}>
                    <Icon name="briefcase" /> {Enums.APP_NAMES[Enums.APP_IDS.BPM]}
                  </Menu.Item>
                  <Menu.Item key={Enums.APP_IDS.ROLES}>
                    <Icon name="user" /> {Enums.APP_NAMES[Enums.APP_IDS.ROLES]}
                  </Menu.Item>
              </SubMenu>
              </Menu>
          </Drawer>
          <Modal
            title="Agilit-e API Key"
            okText="Submit"
            visible={this.state.createTeamDialog.open}
            onOk={this.authenticate}
            onCancel={this.closeTeamDialog}
          >
            <TextArea
              placeholder="Paste API Key here" 
              required={true}
              rows={4}
              defaultValue={this.state.createTeamDialog.apiKey}
              onChange={(e) => {
                this.changeApiKey(e.target.value)
              }}
            />
          </Modal>
      </div>
    )
  }
}

export default CoreToolbar;
