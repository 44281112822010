import Enums from "../utils/enums";
import Globals from "../utils/globals";
import Axios from "axios";
import MemoryStore from "../utils/memory-store";

export const fetchKeys = (callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/bpm/data`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const registerBPMRecord = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/bpm/registerBPMRecord`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.PROCESS_KEY] = state.key;
    config.headers["current-user"] = state.currentUser;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const getRecordState = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/bpm/getRecordState`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.PROCESS_KEY] = state.key;
    config.headers["bpm-record-ids"] = state.recordId;
    config.headers["responsible-users"] = state.responsibleUsers;
    config.headers["step-names"] = state.stepNames;
    config.headers["relevant-users"] = state.relevantUsers;
    config.headers["include-history"] = state.history;
    config.headers["include-step-options"] = state.stepOptions;
    config.headers["include-visible-objects"] = state.visibleObjects;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const getRelevantRecords = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/bpm/getRelevantRecords`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers["relevant-user"] = state.relevantUsers;
    config.headers["process-keys"] = state.key;
    config.headers["include-history"] = state.history;
    config.headers["include-step-options"] = state.stepOptions;
    config.headers["include-visible-objects"] = state.visibleObjects;

    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const getActiveSteps = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/bpm/getActiveSteps`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers["process-key"] = state.key;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const getActiveUsers = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/bpm/getActiveUsers`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers["process-key"] = state.key;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const getById = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/bpm/data/getById`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.RECORD_ID] = state.recordId;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const getByProfileKey = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/bpm/data/getByProfileKey`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.PROFILE_KEY] = state.key;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const execute = (state, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.POST,
        url: `${Globals.apiUrl}/bpm/execute`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.PROCESS_KEY] = state.key;
    config.headers[Enums.HEADER_PROPS.BPM_RECORD_ID] = state.recordId;
    config.headers["option-selected"] = state.optionSelected;
    config.headers[Enums.HEADER_PROPS.CURRENT_USER] = state.currentUser;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};
