import React from 'react';
import moment from 'moment';

import {List, Table, Form, Button, Col, Row, Card, Input, message, Comment, Select, Switch} from 'antd';
import {columnTemplates} from '../../utils/templates';

import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/xcode';

const Option = Select.Option;

const CommentList = ({ comments }) => (
	<List
	  dataSource={comments}
	  header={`${comments.length} ${comments.length > 1 ? 'results' : 'result'}`}
	  itemLayout="horizontal"
	  renderItem={props => <Comment {...props} />}
	/>
);

class KeywordsForm extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			key: "",
			listData: [],
			actionType: "getByProfileKey",
			data: null,
			result: "",
			profileKeys: []
		};

		//Setup Event Binding
		this.onFieldChange = this.onFieldChange.bind(this);
		this.validateExecution = this.validateExecution.bind(this);
	}

	componentDidMount(){
		let tmpThis = this;
		let tmpArr = [];
		let msg = "";
		let success = true;
		
		this.props.fetchKeys(function(err, result){
			if(err){
				success = false;
				msg = err;
			}else if(result.status !== 200){
				success = false;
				msg = result.data.errorMessage;
			}

			if(success){
				result.data.map((entry) => {
					return tmpArr.push(entry.data.key);
				})

				tmpThis.setState({
					profileKeys: tmpArr
				})

			}else{
				return message.error(msg);
			}
		})
	}

	onFieldChange(key, value){
		let tmp = {};
		tmp[key] = value;
		this.setState(tmp);
	}

	validateExecution = () => {
		let tmpThis = this;
		let msg = "";
		let success = true;
		let content = null;

		//First Validate
		//TODO: Validate Data before passing

		this.props.execute(this.state.key, this.state.actionType, this.state.data, function(err, result){
			if(err){
				success = false;
				msg = err;
			}else if(result.status !== 200){
				success = false;
				msg = result.data.errorMessage;
			}else{
				msg = "Keywords Service executed successfully";
			}

			if(success){
				message.success(msg);

				switch(tmpThis.state.actionType){
					case "getByProfileKey":
						//Add keys to result data
						for(let x in result.data.data)
							result.data.data[x].key = x;

						content = <Table columns={columnTemplates.keywords} bordered dataSource={result.data.data} pagination={false} />;
						break;
					case "getProfileKeysByGroup":
						content = (
							<Card title="Profile Keys">
								{result.data.map(entry => {
									return <p key={entry}>{entry}</p>
								})}
							</Card>
						)
						break;
					case "getValueByLabel":
						content = (
							<Card title="Value">
								{result.data.data}
							</Card>
						)
						break;
					case "getLabelByValue":
						content = (
							<Card title="Label">
								{result.data.data}
							</Card>
						)
						break;
					default:

				}

				//Update the state with new values
				tmpThis.setState({
					result: result.data,
					listData: [
						{
						  author: tmpThis.state.key,
						  content,
						  datetime: moment().toString(),
						},
						...tmpThis.state.listData,
					  ],
				});
			}else{
				return message.error(msg);
			}
		});
	}
	  
	render(){
		return(
			<Row  gutter={23} type="flex" justify="center"> 
				<Col xs={23}>
					<Card
						title="Keywords Demo"
						style={{textAlign: "left"}}
						type="inner"
					>
						<Form
							autoComplete="off"
							onSubmit={e => {
								e.preventDefault()
								this.validateExecution();
							}}
						>
							<Row gutter={24}>
								<Col xs={24} sm={24} md={7} lg={7}>
									<Form.Item>
										Action Type
										<Select
											defaultValue={this.state.actionType}	
											onChange={(e) => {
												this.onFieldChange("actionType", e)
											}} 
										>
											<Option value="getByProfileKey">Get By Profile Key</Option>
											<Option value="getProfileKeysByGroup">Get Profile Keys By Group</Option>
											<Option value="getLabelByValue">Get Label By Value</Option>
											<Option value="getValueByLabel">Get Value By Label</Option>
										</Select>
									</Form.Item>
									{this.state.actionType === "getByProfileKey" || this.state.actionType === "getLabelByValue" || this.state.actionType === "getValueByLabel" ? 
										<Form.Item>
										<span style={{color: "red"}}>* </span>Profile Key
										<Select
											onChange={(e) => {
												this.onFieldChange("key", e)
											}}
										>
											{this.state.profileKeys.map((entry) => {
												return <Select.Option value={entry}>{entry}</Select.Option>
											})}
										</Select>
									</Form.Item>
									: null}
									{this.state.actionType === "getProfileKeysByGroup" ? 
										<Form.Item>
											<span style={{color: "red"}}>* </span>Group Name
											<Input
												placeholder="Provide a Group Name"
												onChange={(e) => {
													this.onFieldChange("data", e.target.value)
												}}
												required={true}
											/>
										</Form.Item>
									: null}
									{this.state.actionType === "getLabelByValue" ? 
										<Form.Item>
											<span style={{color: "red"}}>* </span>Value
											<Input
												placeholder="Provide a Keywords Value"
												onChange={(e) => {
													this.onFieldChange("data", e.target.value)
												}}
												required={true}
											/>
										</Form.Item>
									: null}
									{this.state.actionType === "getValueByLabel" ? 
										<Form.Item>
											<span style={{color: "red"}}>* </span>Label
											<Input
												placeholder="Provide a Keywords Label"
												onChange={(e) => {
													this.onFieldChange("data", e.target.value)
												}}
												required={true}
											/>
										</Form.Item>
									: null}
									<Form.Item>
										<Button
											htmlType="submit"
											type="default"
											style={{backgroundColor:"#67AD5B", color:"white", width: "auto", marginRight: "10px", float: "left"}}
										>
											Run
										</Button>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={9} lg={9}>
								{this.state.result !== "" ?
								<div>
								<h4>{`Response`}</h4>
									<AceEditor
										readOnly={true}
										showPrintMargin={false}
										cursorStart={10}
										mode="json"
										theme="xcode"
										style={{width:"100%"}}
										name="aceEditor2"
										value={JSON.stringify(this.state.result, null, "\t")}
										setOptions={{
											showLineNumbers: true,
											newLineMode:"unix"
										}}
										wrapEnabled={true}
									/>
								</div>
								:null}
								</Col>
								<Col xs={24} sm={24} md={8} lg={8}>
									<div>
										{this.state.listData.length > 0 && <CommentList comments={this.state.listData} />}
									</div>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		)
	}
}

export default KeywordsForm;
