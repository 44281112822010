import React from 'react';
import moment from 'moment';

import {List, Form, Button, Col, Row, Card, Input, message, Comment, Switch, Select} from 'antd';

import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/xcode';

const CommentList = ({ comments }) => (
	<List
	  dataSource={comments}
	  header={`${comments.length} ${comments.length > 1 ? 'results' : 'result'}`}
	  itemLayout="horizontal"
	  renderItem={props => <Comment {...props} />}
	/>
);

class BpmForm extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			actionType: "registerBPMRecord",
			recordId: "",
			key: "",
			optionSelected: "",
			currentUser: "",
			responsibleUsers: "",
			stepNames: "",
			relevantUsers: "",
			history: false,
			stepOptions: false,
			visibleObjects: false,
			process: "",
			processes: [],
			parameters: JSON.stringify({
				param1: "",
				param2: ""
			}, null, '\t'),
			result: "",
			profileKeys: []
		};

		//Setup Event Binding
		this.onFieldChange = this.onFieldChange.bind(this);
		this.execute = this.execute.bind(this);
	}

	componentDidMount(){
		let tmpThis = this;
		let tmpArr = [];
		let msg = "";
		let success = true;
		
		this.props.fetchKeys(function(err, result){
			if(err){
				success = false;
				msg = err;
			}else if(result.status !== 200){
				success = false;
				msg = result.data.errorMessage;
			}

			if(success){
				result.data.map((entry) => {
					return tmpArr.push(entry.data.key);
				})

				tmpThis.setState({
					profileKeys: tmpArr
				})

			}else{
				return message.error(msg);
			}
		})
	}

	onFieldChange(key, value){
		let tmp = {};
		tmp[key] = value;
		this.setState(tmp);
	}

	execute = () => {
		let tmpThis = this;
		let msg = "";
		let success = true;

		tmpThis.props[tmpThis.state.actionType](tmpThis.state, function(err, result){
			if(err){
				success = false;
				msg = err;
			}else if(result.status !== 200){
				success = false;
				msg = result.data.errorMessage;
			}else{
				msg = "BPM Service executed successfully";
			}

			if(success){
				message.success(msg);

				//Update the state with new values
				tmpThis.setState({
					result: result.data,
					process: result.data,
					processes: [
						{
						  author: tmpThis.state.key,
						  content: (
							  <Card>
							  {`Success: ${JSON.stringify(success)}`}
							  <br />
							  {`Status: ${JSON.stringify(result.status)}`}
							  </Card>
							),
						  datetime: moment().toString(),
						},
						...tmpThis.state.processes,
					  ],
				});
			}else{
				return message.error(msg);
			}
		});
	}
	  
	render(){
		return(
			<Row  gutter={23} type="flex" justify="center"> 
				<Col xs={23}>
					<Card
						title="BPM Demo"
						style={{textAlign: "left"}}
						type="inner"
					>
						<Form
							autoComplete="off"
							onSubmit={e => {
								e.preventDefault()
								this.execute();
							}}
						>
							<Row gutter={24}>
								<Col xs={24} sm={24} md={12} lg={7}>
									<Form.Item>
										Action Type
										<Select
											defaultValue={this.state.actionType}
											onChange={(e) => {
												this.onFieldChange("actionType", e)
											}} 
										>
											<Select.Option value="registerBPMRecord">Register BPM Record</Select.Option>
											<Select.Option value="execute">Execute</Select.Option>
											<Select.Option value="getRecordState">Get Record State</Select.Option>
											<Select.Option value="getById">Get By Id</Select.Option>
											<Select.Option value="getByProfileKey">Get By Profile Key</Select.Option>
											<Select.Option value="getRelevantRecords">Get Relevant Records</Select.Option>
											<Select.Option value="getActiveSteps">Get Active Steps</Select.Option>
											<Select.Option value="getActiveUsers">Get Active Users</Select.Option>
										</Select>
									</Form.Item>
									{this.state.actionType === "getById" ? 
									<Form.Item>
										<span style={{color: "red"}}>* </span>Record ID
										<Input
											placeholder="Provide a BPM Record ID"
											onChange={(e) => {
												this.onFieldChange("recordId", e.target.value)
											}}
											required={true}
										/>
									</Form.Item>
									: null}
									{this.state.actionType === "getByProfileKey" || this.state.actionType === "registerBPMRecord" || this.state.actionType === "getRecordState" || this.state.actionType === "getActiveSteps" || this.state.actionType === "getActiveUsers" ? 
										<Form.Item>
											<span style={{color: "red"}}>* </span>Profile Key
											<Select
												onChange={(e) => {
													this.onFieldChange("key", e)
												}}
											>
												{this.state.profileKeys.map((entry) => {
													return <Select.Option value={entry}>{entry}</Select.Option>
												})}
											</Select>
										</Form.Item>
									: null}
									{this.state.actionType === "registerBPMRecord" ? 
										<Form.Item>
											<span style={{color: "red"}}>* </span>Current User
											<Input
												onChange={(e) => {
													this.onFieldChange("currentUser", e.target.value)
												}}
												required={true}
											/>
										</Form.Item>	
									:null}
									{this.state.actionType === "getRecordState" ? 
										<Form.Item>
											BPM Record Ids (separate using commas)
											<Input
												onChange={(e) => {
													this.onFieldChange("recordId", e.target.value)
												}}
												required={false}
											/>
										</Form.Item>
									:null}
									{this.state.actionType === "getRecordState" ? 
										<Form.Item>
											Responsible Users (separate using commas)
											<Input
												onChange={(e) => {
													this.onFieldChange("responsibleUsers", e.target.value)
												}}
												required={false}
											/>
										</Form.Item>
									:null}
									{this.state.actionType === "getRecordState" ? 
										<Form.Item>
											Step Names (separate using commas)
											<Input
												onChange={(e) => {
													this.onFieldChange("stepNames", e.target.value)
												}}
												required={false}
											/>
										</Form.Item>
									:null}
									{this.state.actionType === "getRecordState" ? 
										<Form.Item>
											Relevant Users (separate using commas)
											<Input
												onChange={(e) => {
													this.onFieldChange("relevantUsers", e.target.value)
												}}
												required={false}
											/>
										</Form.Item>
									:null}
									{this.state.actionType === "getRelevantRecords" ? 
										<Form.Item>
											<span style={{color: "red"}}>* </span>Relevant User
											<Input
												onChange={(e) => {
													this.onFieldChange("relevantUsers", e.target.value)
												}}
												required={true}
											/>
										</Form.Item>
									:null}
									{this.state.actionType === "getRelevantRecords" ? 
										<Form.Item>
											Profile Keys (separate using commas)
											<Input
												onChange={(e) => {
													this.onFieldChange("key", e.target.value)
												}}
												required={false}
											/>
										</Form.Item>
									:null}
									{this.state.actionType === "getRecordState" || this.state.actionType === "getRelevantRecords" ? 
										<Form.Item>
										<Switch 
											defaultChecked={false}
											checkedChildren={"Include History"}
											unCheckedChildren={"Exclude History"}
											onChange={(e) => {
												this.onFieldChange("history", e)
											}}
										/>
										</Form.Item>
									:null}
									{this.state.actionType === "getRecordState" || this.state.actionType === "getRelevantRecords"  ? 
										<Form.Item>
										<Switch 
											defaultChecked={false}
											checkedChildren={"Include Step Options"}
											unCheckedChildren={"Exclude Step Options"}
											onChange={(e) => {
												this.onFieldChange("stepOptions", e)
											}}
										/>
										</Form.Item>
									:null}
									{this.state.actionType === "getRecordState" || this.state.actionType === "getRelevantRecords"  ? 
										<Form.Item>
										<Switch 
											defaultChecked={false}
											checkedChildren={"Include Visible Objects"}
											unCheckedChildren={"Exclude Visible Objects"}
											onChange={(e) => {
												this.onFieldChange("visibleObjects", e)
											}}
										/>
										</Form.Item>
									:null}
									{this.state.actionType === "execute" ? 
										<div>
											<Form.Item>
												Profile Key
												<Select
													defaultValue={this.state.profileKeys[0]}
													onChange={(e) => {
														this.onFieldChange("key", e)
													}}
												>
													{this.state.profileKeys.map((entry) => {
														return <Select.Option value={entry}>{entry}</Select.Option>
													})}
												</Select>
											</Form.Item>
											<Form.Item>
												<span style={{color: "red"}}>* </span>BPM Record Id
												<Input
													placeholder="Provide a BPM Record Id"
													onChange={(e) => {
														this.onFieldChange("recordId", e.target.value)
													}}
													required={true}
												/>
											</Form.Item>
											<Form.Item>
												<span style={{color: "red"}}>* </span>Option Selected
												<Input
													onChange={(e) => {
														this.onFieldChange("optionSelected", e.target.value)
													}}
													required={true}
												/>
											</Form.Item>
											<Form.Item>
												<span style={{color: "red"}}>* </span>Current User
												<Input
													onChange={(e) => {
														this.onFieldChange("currentUser", e.target.value)
													}}
													required={true}
												/>
											</Form.Item>
										</div>
									: null}
									{this.state.actionType === "execute" ?
									<Form.Item>
									Request Body (JSON)
										<AceEditor
											showPrintMargin={false}
											cursorStart={1}
											mode="json"
											theme="xcode"
											style={{width:"100%", height: 300}}
											name="aceEditor2"
											value={this.state.parameters}
											setOptions={{
												showLineNumbers: true,
												newLineMode:"unix"
											}}
											wrapEnabled={true}
											onChange={(e) => {
												this.onFieldChange("parameters", e);
											}}
										/>
									</Form.Item>
									:null}
									<Form.Item>
										<Button
											type="default"
											htmlType="submit"
											style={{backgroundColor:"#67AD5B", color:"white", width: "auto", marginRight: "10px", float: "left"}}
										>
											Run
										</Button>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={18} lg={9}>
								{this.state.result !== "" ?
								<div>
								<h3>{`Response`}</h3>
									<AceEditor
										readOnly={true}
										showPrintMargin={false}
										cursorStart={10}
										mode="json"
										theme="xcode"
										style={{width:"100%"}}
										name="aceEditor2"
										value={JSON.stringify(this.state.result, null, "\t")}
										setOptions={{
											showLineNumbers: true,
											newLineMode:"unix"
										}}
										wrapEnabled={true}
									/>
								</div>
								:null}
								</Col>
								<Col xs={24} sm={24} md={12} lg={8}>
									<div>
										{this.state.processes.length > 0 && <CommentList comments={this.state.processes} />}
									</div>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		)
	}
}

export default BpmForm;
