import coreState from './core-state';
import {actions} from './core-actions';

const core = (state = coreState, action) => {
    switch (action.type) {
        case actions.UPDATE_TABS:
            let tabArray = state.tabObject.tabs;

            //If tab exists, activate it
            for(let i in tabArray){
                if(tabArray[i].key === action.payload.key){
                    return Object.assign({}, state, {
                        tabObject: {
                            tabs: tabArray,
                            activeKey: action.payload.key,
                            activeApp: action.payload.app,
                            tabType: action.payload.tabType
                        }
                    });
                }
            }

            //If we get here, we need to add a new Tab
            tabArray.push(action.payload);

            return Object.assign({}, state, {
                tabObject: {
                    tabs: tabArray,
                    activeKey: action.payload.key,
                    activeApp: action.payload.app,
                    tabType: action.payload.tabType
                }
            });
        case actions.CHANGE_TAB:
            return Object.assign({}, state, {
                tabObject: {
                    ...state.tabObject,
                    activeKey: action.key,
                    activeApp: action.app,
                    tabType: action.tabType
                }
            });
        case actions.CLOSE_TAB:
            return Object.assign({}, state, {
                tabObject: {
                    tabs: action.tabs,
                    activeKey: action.activeKey,
                    activeApp: action.activeApp,
                    tabType: action.tabType
                }
            });
        default:
            return state
    }
}

export default core;