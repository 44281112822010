import Enums from "../utils/enums";
import Globals from "../utils/globals";
import Axios from "axios";
import MemoryStore from "../utils/memory-store";

export const fetchKeys = (callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/connectors/data`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const execute = (key, routeKey, paramData, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.POST,
        url: `${Globals.apiUrl}/connectors/execute`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    config.headers[Enums.HEADER_PROPS.PROFILE_KEY] = key;
    config.headers[Enums.HEADER_PROPS.ROUTE_KEY] = routeKey;

    config.data = paramData;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};