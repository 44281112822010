import Enums from "../utils/enums";
import Globals from "../utils/globals";
import Axios from "axios";
import MemoryStore from "../utils/memory-store";

export const fetchKeys = (callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/keywords/data`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;
    
    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const execute = (key, actionType, headerData, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/keywords/${actionType}`,
        data: {}
    };

    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey;

    switch(actionType){
        case "getByProfileKey":
            config.headers[Enums.HEADER_PROPS.PROFILE_KEY] = key;
            break;
        case "getProfileKeysByGroup":
            config.headers["group-name"] = headerData;
            break;
        case "getLabelByValue":
            config.headers["value-key"] = headerData;
            config.headers[Enums.HEADER_PROPS.PROFILE_KEY] = key;
            break;
        case "getValueByLabel":
            config.headers["label-key"] = headerData;
            config.headers[Enums.HEADER_PROPS.PROFILE_KEY] = key;
            break;
        default:
    }

    Axios.request(config)
    .then(function (response){
        callback(null, response);   
    })
    .catch(function (error){
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};