import axios from 'axios';

import MemoryStore from '../utils/memory-store';
import Globals from '../utils/globals';
import Enums from '../utils/enums';

export const actions = {
    UPDATE_TABS: 'UPDATE_TABS',
    CHANGE_TAB: 'CHANGE_TAB',
    CLOSE_TAB: 'CLOSE_TAB'
};

export const authenticateToken = (apiKey, callback) => {
    let config = {
        headers: {},
        method: Enums.REQ_TYPE.GET,
        url: `${Globals.apiUrl}/user/authenticatetoken`
    };

    config.headers[Enums.HEADER_PROPS.API_KEY] = apiKey;

    axios.request(config)
    .then(function (response){
        //Update Memory Story
        MemoryStore.apiKey = apiKey;
        MemoryStore.teamId = response.data.teamId;

        callback(null, response);   
    })
    .catch(function (error){
        console.log(error);
        if(error.response){
            callback(null, error.response);
        }else{
            callback("Unknown Error Occurred", null);
        }
    });
};

export const addTab = (payload) => {
    return dispatch => {
        dispatch({
            type: actions.UPDATE_TABS,
            payload
        })
    };
};

export const changeTab = (key, tabs) => {
    let tabType = "";
    let app = "";

    //Get the active Tab to return tabType
    for(let x in tabs)
        if(tabs[x].key === key){
            app = tabs[x].app
            tabType = tabs[x].tabType
        }

    return dispatch => {
        dispatch({
            type: actions.CHANGE_TAB,
            key,
            app,
            tabType
        })
    };
};

export const closeTab = (activeKey, targetKey, tabs) => {
    let lastIndex = null;
    let tabType = "";
    let activeApp = "";

    tabs.forEach((pane, i) => {
      if (pane.key === targetKey){
        lastIndex = i - 1;
      }
    });

    const newTabs = tabs.filter(pane => pane.key !== targetKey);

    if (lastIndex >= 0 && (activeKey === targetKey)){
        activeKey = newTabs[lastIndex].key;
        activeApp = newTabs[lastIndex].app;
        tabType = newTabs[lastIndex].tabType;
    }
      
    return dispatch => {
        dispatch({
            type: actions.CLOSE_TAB,
            tabs: newTabs,
            activeKey,
            activeApp,
            tabType
        });
    };  
};